<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="台账月份">
          <el-input clearable v-model="search.searchFields.month" placeholder="请输入台账月份"></el-input>
        </el-form-item>
        <el-form-item label="台账类型" >
          <el-select clearable v-model="search.searchFields.type" placeholder="请选择台账类型">
            <el-option value="1" label="台账">台账</el-option>
            <el-option value="3" label="结转成本">结转成本</el-option>
            <el-option value="4" label="开票成本">开票成本</el-option>
            <el-option value="5" label="采购订单">采购订单</el-option>
            <el-option value="6" label="实际付款">实际付款</el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="台账子类型" v-if="search.searchFields.type == 1">
          <el-select clearable v-model="search.searchFields.c_type" placeholder="请选择台账子类型" >
            <el-option value="1">OI</el-option>
            <el-option value="2">BDF</el-option>
            <el-option value="3">返佣</el-option>
            <el-option value="4">营销服务费</el-option>
            <el-option value="5">系统服务费</el-option>
            <el-option value="6">管理费用</el-option>
            <el-option value="7">税费</el-option>
            <el-option value="8">物流费</el-option>
            <el-option value="9">运维费</el-option>
            <el-option value="10">BA贴花</el-option>
            <el-option value="20" label="进项开票金额">进项开票金额</el-option>
            <el-option value="21" label="进项税额">进项税额</el-option>
            <el-option value="22" label="缴纳增值税额">缴纳增值税额</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="台账品牌" v-if="search.searchFields.type != 1">
          <el-input clearable v-model="search.searchFields.c_type" placeholder="请添加品牌" ></el-input>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getBudgetList(1)">查询</el-button>
        <el-button type="primary" size="small" @click="clearSearch()">重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
        <el-button type="primary" size="small" @click="handleExportLedger()">下载台账</el-button>
      </div>
      <v-table
          :data="options.data"
          :columns="options.columns"
          :isBackPage="true"
          :totalCount="options.totalCount"
          :defaultcurrentPage="search.searchFields.page"
          @handleCurrentChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
        title="新增"
        width="25%"
        :visible.sync="exportLedgerLog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="台账年份"
            required
            :error="errorBags.collect('台账年份')[0]"
        >
          <el-input
              v-model="budgetMonthForm.year"
              placeholder="请输入台账年份"
              v-validate="'required'"
              data-vv-name="台账年份"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-container">
        <el-button @click="closeLedgerModal">取消</el-button>
        <el-button type="primary" @click="handleLedgerSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="新增"
        width="25%"
        :visible.sync="shopAddDialog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="台账月份"
            required
            :error="errorBags.collect('台账月份')[0]"
        >
          <el-input
              v-model="budgetForm.month"
              placeholder="请输入台账月份"
              v-validate="'required'"
              data-vv-name="台账月份"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="台账类型"
            required
            :error="errorBags.collect('台账类型')[0]"
        >
          <el-select
              v-model="budgetForm.type"
              placeholder="请输入台账类型"
              v-validate="'required'"
              data-vv-name="台账类型"
          >
            <el-option value="1" label="台账">台账</el-option>
            <el-option value="3" label="结转成本">结转成本</el-option>
            <el-option value="4" label="开票成本">开票成本</el-option>
            <el-option value="5" label="采购订单">采购订单</el-option>
            <el-option value="6" label="实际付款">实际付款</el-option>

          </el-select>
        </el-form-item>
        <el-form-item
            label="台账子类型"
            required
            :error="errorBags.collect('台账子类型')[0]"
        >
          <el-select clearable v-model="budgetForm.c_type" placeholder="请选择台账子类型" v-if="budgetForm.type == 1">
            <el-option value="1" label="OI">OI</el-option>
            <el-option value="2" label="BDF">BDF</el-option>
            <el-option value="3" label="返佣">返佣</el-option>
            <el-option value="4" label="营销服务费" >营销服务费</el-option>
            <el-option value="5" label="系统服务费" >系统服务费</el-option>
            <el-option value="6" label="管理费用" >管理费用</el-option>
            <el-option value="7" label="税费" >税费</el-option>
            <el-option value="8" label="物流费">物流费</el-option>
            <el-option value="9" label="运维费">运维费</el-option>
            <el-option value="10" label="BA贴花">BA贴花</el-option>
            <el-option value="20" label="进项开票金额">进项开票金额</el-option>
            <el-option value="21" label="进项税额">进项税额</el-option>
            <el-option value="22" label="缴纳增值税额">缴纳增值税额</el-option>
          </el-select>
          <el-input clearable v-model="budgetForm.c_type" placeholder="请选择台账子类型" v-if="budgetForm.type != 1" />
        </el-form-item>
        <el-form-item
            label="台账金额"
            required
            :error="errorBags.collect('台账金额')[0]"
        >
          <el-input
              v-model="budgetForm.price"
              maxlength="12"
              placeholder="请输入台账金额"
              v-validate="'required'"
              data-vv-name="台账金额"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
          <el-select v-model="budgetForm.status" v-validate="'required'" data-vv-name="状态">
            <el-option
                v-for="item in statusOptions"
                :key="item.code"
                :label="item.value"
                :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="btn-container">
          <el-button @click="closeModal">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import {BAApi} from '@/api'
import {formatDate} from '@/libs/utils'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '台账月份',
            key: 'month'
          },
          {
            label: '台账类型',
            key: 'type_msg'
          },
          {
            label: '台账子类型',
            key: 'c_type_msg'
          },
          {
            label: '台账金额',
            key: 'price'
          },
          {
            label: '状态',
            key: 'status'
          },
          {
            label: '创建时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '更新时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.update_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              },
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          month: '',
          c_type: '',
          type: [1,3,4,5,6],
        }
      },
      statusOptions: [
        {
          code: 1,
          value: '正常'
        }, {
          code: 0,
          value: '下架'
        },
      ],
      exportLedgerLog: false,
      shopAddDialog: false,
      budgetMonthForm: {
        month: '',
        type: 2,
        status: 1
      },
      budgetForm: {
        type:1,
        month:'',
        c_type:'',
        price:'',
        status: 1
      },
      editBudgetId: '',
    }
  },

  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getBudgetList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getBudgetList(1)
    },
    async getBudgetList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getLedgerCashList(this.search.searchFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          this.options.data = data.data.map(item => {
            item.btnList = ['edit']
            return item
          })
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({count: this.search.searchFields.count}, {
          page: 1,
          month: '',
          type:'',
          c_type: '',
        })
      }
    },
    async handleButtonClick(value) {
      let type = value.button,
          data = value.data;
      this.editBudgetId = data.id;
      if (type === 'edit') {
        this.shopAddDialog = true;
        this.budgetForm.type = data.type;
        this.budgetForm.c_type = data.c_type;
        this.budgetForm.month = data.month;
        this.budgetForm.status = Number(data.status);
        this.budgetForm.price = data.price;
      }
    },
    handleExportLedger() {
      this.exportLedgerLog = true
    },
    closeLedgerModal() {
      this.resetSMForm()
      this.exportLedgerLog = false
    },
    handleAdd() {
      this.resetForm()
      this.$validator.reset()
      this.shopAddDialog = true
    },
    closeModal() {
      this.resetForm()
      this.shopAddDialog = false
    },
    handleLedgerSubmit() {
      this.$validator.validate().then(year => {
        if (year) {
          this.exportBudgetSM()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async exportBudgetSM() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetMonthForm,
          type: 2
        }
        let data = (await BAApi.exportBudget(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.resetSMForm()
          this.exportLedgerLog = false
          let time = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss').dateString
          let downLink = document.createElement('a')
          downLink.href = data.data.file_path
          downLink.download = `${time}-预算年度列表`
          downLink.click()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    handleSubmit() {
      this.$validator.validate().then(month => {
        if (month) {
          this.addBudget()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async addBudget() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetForm,
          id: this.editBudgetId,
        }
          let data = (await BAApi.addOrEditLC(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加台账成功')
          this.resetForm()
          this.shopAddDialog = false
          this.getBudgetList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },

    resetForm() {
      let obj = {
        month:'',
        c_type :'',
        price:'',
        type:[1,3,4,5,6]
      }
      this.budgetForm = obj
      this.editBudgetId = '';
    },
    resetSMForm() {
      let obj = {
        year: ''
      }
      this.budgetMonthForm = obj
    }
  },

  mounted() {
    this.getBudgetList()
  },
  components: {
    vTable, searchField
  }
}
</script>

<style lang="less">
.status-td {
  display: flex;
  align-items: center;
  justify-content: center;

  .tag {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tag2 {
    background-color: #67C23A;
  }

  .tag3 {
    background-color: #F56C6C;
  }
}
</style>

